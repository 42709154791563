import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { useAuth } from "../../context/AuthProvider";
import { BoomBox, MicVocal, UsersRound, BadgePlus, Mail } from "lucide-react";

import { Button, SecondaryButton, cn } from "@jammable/ui-core";

import { ChevronDown, Menu, X } from "lucide-react";
import DropDownLink from "./dropdown/DropDownLink";
import ProfileMenu from "./ProfileMenu";
import useSubscription from "../../hooks/useSubscription";
import UpgradeModal from "../modals/UpgradeModal";
import MobileMenu from "./MobileMenu";
import Dropdown from "../dropdown/Dropdown.js";
import DropdownTrigger from "../dropdown/DropdownTrigger";
import MessageIcon from "./MessageIcon.js";
const Header = () => {
  const router = useRouter();
  const pathname = usePathname();
  const { user, loading } = useAuth();

  const isAuthenticated = !!user;
  console.log(isAuthenticated);
  const { hasCreatorPlan, hasPowerPlan } = useSubscription();
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const isCinematic = router.pathname.includes("/duets");

  const logoSize = 47;

  const [isOpen, setIsOpen] = useState(false);
  const isTouchDevice =
    typeof window !== "undefined" && "ontouchstart" in window;

  return (
    <div className="tw-w-full tw-flex tw-justify-center">
      {!isCinematic && (
        <div className="blur tw-mt-8  tw-w-full  tw-max-w-[1400px]  tw-h-[65px] md:tw-h-20" />
      )}

      <div
        className={`tw-fixed tw-transition-all ${isCinematic ? "tw-border-slate-950" : ""} tw-border tw-border-solid tw-border-gray-100 tw-py-4 tw-max-w-[1400px] tw-duration-200 ${isCinematic ? "tw-text-white tw-bg-slate-950/95 " : "tw-bg-white"}  tw-w-full tw-w-full  tw-z-[100]  tw-rounded-b-2xl md:tw-rounded-5xl  tw-h-[70px] md:tw-h-20 tw-flex tw-items-center tw-pl-2 tw-px-3 md:tw-px-5 tw-justify-center tw-mt-7 tw-top-0`}
      >
        <div className="tw-w-full tw-flex tw-items-center">
          <div
            className="tw-flex tw-cursor-pointer md:tw-hidden tw-p-2 tw-h-full"
            onClick={toggleMobileMenu}
          >
            {mobileMenuOpen ? <X /> : <Menu />}
          </div>
          <Link
            href={"/"}
            className="tw-flex tw-items-center tw-cursor-pointer"
          >
            <Image
              src={logo}
              width={logoSize}
              height={logoSize}
              priority
              alt="Jammable Logo"
              quality={100}
              className="tw-cursor-pointer tw-scale-90 md:tw-scale-100 tw-mr-1.5 tw-transition-all tw-duration-200"
            />
            <div
              className={`tw-hidden md:tw-block tw-text-3xl tw-tracking-tighter tw-transition-all tw-pr-1 tw-bg-clip-text tw-text-transparent ${isCinematic ? " tw-text-white" : "tw-bg-gradient-to-r tw-from-pink-300/90 tw-to-purple-700"} tw-font-bold`}
            >
              Jammable
            </div>
          </Link>

          <div className="tw-hidden md:tw-flex tw-items-center tw-gap-2 tw-ml-5">
            <Link
              href={"/"}
              className={`tw-font-bold tw-cursor-pointer ${isCinematic ? "hover:tw-bg-gray-100/10" : "hover:tw-bg-gray-100"}  tw-transition-all tw-p-1.5 tw-px-3 tw-rounded-full hover: ${pathname === "/" && "tw-bg-gray-100 "}`}
            >
              Home
            </Link>

            <Dropdown
              dropdownItems={
                <>
                  {" "}
                  <DropDownLink
                    href={`/models`}
                    title="Voice Library"
                    description="Use the entire Jammable voice library."
                    icon={<MicVocal />}
                  />
                  <DropDownLink
                    href={`/train`}
                    title="Train voice"
                    description="Create and share your own voice model."
                    icon={<BadgePlus />}
                  />
                  <DropDownLink
                    href={`/duets`}
                    title="Duets"
                    description="Use multiple voices together!"
                    icon={<UsersRound />}
                  />
                </>
              }
            >
              <div
                className={`tw-font-bold tw-cursor-pointer  tw-transition-all ${isCinematic ? "hover:tw-bg-gray-100/10" : "hover:tw-bg-gray-100"} tw-p-1.5 hover:tw-rounded-full tw-px-3 tw-flex tw-items-center tw-gap-1`}
              >
                Create
                <ChevronDown
                  strokeWidth={3}
                  className="tw-relative  tw-size-4 tw-transition duration-200 group-data-[state=open]:tw-rotate-180"
                />
              </div>
            </Dropdown>

            <Link
              className={`tw-font-bold tw-cursor-pointer ${isCinematic ? "hover:tw-bg-gray-100/10" : "hover:tw-bg-gray-100"} tw-transition-all tw-p-1.5 tw-px-3 tw-rounded-full ${pathname === "/pricing" && "tw-bg-gray-100"}`}
              href={"/pricing"}
            >
              Pricing
            </Link>
          </div>
          <div className="tw-ml-auto tw-flex tw-items-center tw-gap-3">
            {isAuthenticated && <MessageIcon />}

            <UpgradeModal
              open={upgradeModalOpen}
              setOpen={setUpgradeModalOpen}
              from="header-upgrade"
            />
            {!hasCreatorPlan() &&
              !hasPowerPlan() &&
              !!user?.auth &&
              user?.credits <= 0 && (
                <Button
                  onClick={() => {
                    setUpgradeModalOpen(true);
                  }}
                  auto
                  color="gradient"
                  isUpgrade
                  className="tw-rounded-full md:tw-px-4 tw-h-11 tw-shadow-md"
                >
                  Upgrade
                </Button>
              )}

            {isAuthenticated && user?.avatar ? (
              <ProfileMenu user={user} />
            ) : (
              <div className="tw-ml-auto tw-gap-2 tw-flex tw-text-xs tw-items-center">
                <SecondaryButton
                  href={`/login`}
                  className=" tw-border-none md:tw-px-4 tw-h-10 md:tw-h-11 tw-font-black tw-rounded-full"
                >
                  Log In
                </SecondaryButton>
                <Button
                  href="/signup"
                  className="md:tw-px-4 tw-h-10 md:tw-h-11 tw-rounded-full"
                >
                  Sign Up
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {mobileMenuOpen && (
        <MobileMenu
          mobileMenuOpen={mobileMenuOpen}
          toggleMobileMenu={toggleMobileMenu}
          isAuthenticated={isAuthenticated}
          isCinematic={isCinematic}
        />
      )}
    </div>
  );
};

export default Header;
